<!--
 * @Author: your name
 * @Date: 2020-10-13 15:46:08
 * @LastEditTime: 2020-10-13 18:48:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yue_quanzhan_h5/src/views/blank/index.vue
-->
<template>
  <div />
</template>
<script>
import { getJumpUrlNew } from '@/services/api'

export default {
  data() {
    return {
      //   loading:true
    }
  },
  created() {
    if (localStorage.getItem('uid') && localStorage.getItem('token')) {
      this.getJumpUrlNew()
    } else {
      this.$store.dispatch('loginIntercept', this.$route.query)
    }
  },
  methods: {
    getJumpUrlNew() {
      getJumpUrlNew({
        url: this.$route.query.url,
        room_id: this.$route.query.room_id,
        robot_send: this.$route.query.robot_send,
        uid: localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          // var url = res.data.jumpLink
          window.location.href = res.data.jumpLink
        }
      })
    }
  }
}
</script>
<style lang="" scoped>

</style>
